





















import { UseFields } from 'piramis-base-components/src/components/Pi'
import { Component, Mixins } from 'vue-property-decorator'
import { UsersService } from '@/includes/services/UsersService'
import PageTitle from '@/components/PageTitle.vue'
import MetaDataService from '@/includes/services/MetaDataService'
import { InputSetups } from '@/mixins/input-setups'
import CSVExportService from '@/includes/services/CSVExportService'

import BotUsers from 'piramis-base-components/src/components/BotUsers/BotUsers.vue'
import { BotUsersConfig, GetBotUsersReportResponse } from 'piramis-base-components/src/components/BotUsers/types'
import BotUsersCore from './BotUsersCore'

@Component({
  components: {
    PageTitle,
    BotUsers,
  }
})
export default class Users extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  config: BotUsersConfig | null = null

  exportToCsv(): void {
    CSVExportService.ExportBotUsers('tg', this.$store.state.boardsState.activeBoard!.board)
  }

  goToProfile(id: number): void {
    this.$router.push({
      name: 'User_profile',
      params: {
        id: this.$route.params.id,
        user_id: id.toString()
      }
    })
  }

  getReports(utm?: string): Promise<GetBotUsersReportResponse> {
    const body = Object.assign({ board_key: this.$store.state.boardsState.activeBoard!.board }, ...[ utm ? { utm } : {} ])
    return new Promise(resolve => UsersService.getBotUsersReport('tg', body).then(({ data }) => resolve(data)))
  }

  created(): void {
    this.config = {
      botUsersCore: new BotUsersCore(this.$i18n),
      getUtms: () => new Promise(resolve => {
        MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard?.board })
          .then(({ items }) => {
            resolve(items)
          })
      }),
      getUsersReports: this.getReports
    }
  }
}
