import { User } from 'piramis-base-components/src/components/BotUsers/components/Users/types'
import BotUsersCoreModel from 'piramis-base-components/src/components/BotUsers/components/Users'
import { UsersService } from '../../includes/services/UsersService'
import router from '../../router'
import i18n from 'vue-i18n'
import store from '../../store/store'

export default class BotUsersCore extends BotUsersCoreModel {
	board_key = store.state.boardsState.activeBoard!.board
	board_id = store.state.boardsState.activeBoard!.board.split('.')[0]

	labelsList = store.getters.getLabelsOptions

	constructor(i18n: i18n) {
		super(i18n)
	}

	getUsers(params: { offset: number, limit: number, filter?: string, labels?: Array<number>}): Promise<{ items: Array<User>; count: number }> {
		this.isLoading = true
		return new Promise(resolve => {
			UsersService.getBotUsers('tg', { board_key: this.board_key, ...params })
				.then((res) => resolve(res))
				.finally(() => this.isLoading = false)
		})
	}

	toProfile(id: number) {
		router.push({
			name: 'User_profile',
			params: {
				id: this.board_id,
				user_id: id.toString()
			}
		})
	}

	getLabel(id: number): string {
		const label = store.getters.getLabelsOptions.find(({ label, value }) => value === id)
		return label ? label.label : ''
	}
}
